import ApplicationUser from "./models/ApplicationUser";
import Installation from './models/Installation';
import Segment from './models/Segment';
import ConfigurationSet from './models/ConfigurationSet';
import ConfigurationValue from './models/ConfigurationValue';
import { RemoteCommand } from "./models/RemoteCommand";
import { navigate } from "@reach/router";
import InstallationAndStatistic from "./models/InstallationAndStatistic";
import ReleaseItem, { ReleaseItemState } from "./models/ReleaseItem";

type ErrorHandler = (request: Request, response: Response) => void;
let onError: ErrorHandler = (request, response) => { };

async function send(url: string, init: RequestInit | undefined = undefined, type: string = 'json') {
    const theirRequest = new Request('api' + url, init);
    const ourRequest = new Request(theirRequest, {
        headers: {
            ...theirRequest.headers,
            'Content-Type': 'application/json'
        }
    });

    return fetch(ourRequest).then(async response => {
        if (!response.ok) {
            console.error('BAD RESPONSE ', response.status);
            // onError(ourRequest, response);

            if (response.status === 401) {
                navigate('unauthenticated');
            } else if (response.status === 403) {
                navigate('denied');
            } else {
                navigate('error');
            }

            return Promise.reject(response);
        }

        // 500 errors
        if (response.status > 499 && response.status < 599) {
            // navigate('error');
            return Promise.reject(response);
        }

        if (response.status === 204)
            return Promise.resolve(null);

        let whatever: any = null;
        if (type === 'text') {
            whatever = await response.text();
        } else
            whatever = await response.json();

        return Promise.resolve(whatever);
    });
}

class Users {
    public list(): Promise<ApplicationUser[]> {
        return send('/users').then((data: any) => data.map((d: any) => new ApplicationUser(d)));
    }

    public promote(id: string, roleId: string) {
        send(`/users/${id}/${roleId}`, { method: 'POST' });
    }
}

class ReleaseItems {
    public list(): Promise<ReleaseItemState> {
        return send('/releases');
    }

    public get(id: string): Promise<ReleaseItem> {
        return send(`/releases/${id}`);
    }

    public delete(id: string): Promise<string> {
        return send(`/releases/${id}`, { method: 'DELETE' });
    }
}

class Commands {
    public process(command: RemoteCommand): Promise<string> {
        return send(`/commands/${command.messageGroupId}/${command.installationId}/`, { method: 'PUT', body: JSON.stringify(command) });
    }
}

class Installations {
    public list(): Promise<Installation[]> {
        return send('/installations').then((data: any) => data.map((d: any) => new Installation(d)));
    }

    public find(id: string): Promise<Installation> {
        return send('/installations/' + id).then((data: any) => new Installation(data));
    }

    public update(installation: Partial<Installation>) {
        return send(`/installations/${installation.id}`, { method: 'PATCH', body: JSON.stringify(installation) });
    }

    public delete(installation: Installation) {
        return send(`/installations/${installation.id}`, { method: 'DELETE' });
    }
}

class Segments {
    public list(): Promise<Segment[]> {
        return send('/segments').then((data: any) => data.map((d: any) => new Segment(d)));
    }

    public find(id: string): Promise<Segment> {
        return send('/segments/' + id).then((data: any) => new Segment(data));
    }

    public add(segment: Segment) {
        return send(`/segments/${segment.id}`, { method: 'PUT', body: JSON.stringify(segment) });
    }

    public update(segment: Partial<Segment>) {
        return send(`/segments/${segment.id}`, { method: 'PATCH', body: JSON.stringify(segment) });
    }

    public listInstallations(segment: Segment): Promise<InstallationAndStatistic[]> {
        return send(`/segments/${segment.id}/installations`).then((data: any) => data.map((d: any) => new Installation(d)));
    }

    public addInstallation(installation: Installation) {
        return send(`/segments/${installation.segmentId}/installations/${installation.id}`, { method: 'PUT', body: JSON.stringify(installation) });
    }

    public delete(segment: Segment) {
        return send(`/segments/${segment.id}`, { method: 'DELETE' });
    }
}

class Configuration {
    public listSets(): Promise<ConfigurationSet[]> {
        return send('/configuration').then((data: any) => data.map((d: any) => new ConfigurationSet(d)));
    }

    public listValues(configurationSetId: string): Promise<ConfigurationValue[]> {
        return send(`/configuration/${configurationSetId}`).then((data: any) => data.map((d: any) => new ConfigurationValue(d)));
    }

    public addSet(cs: ConfigurationSet) {
        return send(`/configuration/${cs.id}`, { method: 'PUT', body: JSON.stringify(cs) });
    }

    public addValue(cv: ConfigurationValue) {
        return send(`/configuration/${cv.configurationSetId}/${cv.id}`, { method: 'PUT', body: JSON.stringify(cv) });
    }

    public update(cs: ConfigurationSet) {
        return send(`/configuration/${cs.id}`, { method: 'PATCH', body: JSON.stringify({ 'name': cs.name }) });
    }

    public setDefault(id: ConfigurationSet['id'], isDefault: boolean) {
        return send(`/configuration/${id}`, { method: 'POST', body: JSON.stringify(isDefault) });
    }

    public deleteSet(cs: ConfigurationSet) {
        return send(`/configuration/${cs.id}`, { method: 'DELETE' });
    }

    public deleteValue(cv: ConfigurationValue) {
        return send(`/configuration/${cv.configurationSetId}/${cv.id}`, { method: 'DELETE' });
    }
}

class Api {
    public version(): Promise<string> {
        return send('/info/version', undefined, 'text');
    }

    public logout(): Promise<any> {
        return send('/account/logout', { method: 'DELETE' });
    }

    public commands: Commands = new Commands();
    public users: Users = new Users();
    public releaseItems: ReleaseItems = new ReleaseItems();
    public installations: Installations = new Installations();
    public segments: Segments = new Segments();
    public configuration: Configuration = new Configuration();

    public setErrorHandler(fn: ErrorHandler) {
        onError = fn;
    }
}

const api = new Api();
export default api;